@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

@font-face {
  font-family: "Palatino";
  src: local("PALANormal"),
   url("./fonts/PALANormal.TTF") format("truetype");
  font-weight: normal;
  }

@font-face {
  font-family: "PalatinoBold";
  src: local("PALABold"),
   url("./fonts/PALABold.TTF") format("truetype");
  font-weight: bold;
  }

@font-face {
  font-family: "AcontiumNapellus";
  src: local("AconitumNapellus"),
   url("./fonts/AconitumNapellus.ttf") format("truetype");
  font-weight: normal;
  }  

  @font-face {
    font-family: "PalatinoItalic";
    src: local("PALAItalic"),
     url("./fonts/PALAItalic.TTF") format("truetype");
    font-weight: normal;
    }  

@font-face {
  font-family: "PalatinoBoldItalic";
  src: local("PALABoldItalic"),
   url("./fonts/PALABoldItalic.TTF") format("truetype");
  font-weight: bold;
  }  

.swiper-button-next
{
  margin-right: 1rem;
}
.swiper-button-prev{
  margin-left: 1rem
}

.textAdd{
  margin-left: 1.2rem;
}

body{
  overflow-x: hidden;
}